<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol lg="6" md="6" sm="9">
          <CRow class="login-header">
            <CIcon
                class="c-sidebar-brand-full mb-3"
                name="logo"
                size="custom-size"
                :height="45"
                viewBox="0 0 550 148"
                style="padding-right: 100px;"
            />
          </CRow>
          <CCard class="row mb-3 p-4">
            <CCardBody style="text-align: center">
              <h3>{{ $t("EMAIL_NOT_VERIFIED.HEADER_MSG") }}</h3><br>
              <p>{{ $t("EMAIL_NOT_VERIFIED.TEXT_MSG") }}</p><br>

              <CButton @click="" class="col-12 col-xl-12 px-4 verify-email-button">
                {{
                  $t("EMAIL_NOT_VERIFIED.BUTTON_TEXT")
                }}
              </CButton>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
export default {
  name: "ForgotPassword",
  data() {
    return {

    }
  },
  methods: {

  }
}
</script>